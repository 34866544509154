import axios from "@axios";
import config from "../../../config/config"

export default {
	namespaced: true,
	state: {
		authToken: null,
	},
	getters: {},
	mutations: {
		SET_AUTH_TOKEN: (state, authToken) => {
			state.authToken = authToken;
		},
		FETCH_AUTH_TOKEN_FAILURE: (state) => {
			state.authToken = null;
		},
		LOGOUT: (state) => {
			state.authToken = null;
			state.authTokenExpiry = null;
		},
		SET_AUTH_TOKEN_EXPIRY: (state, expiry) => {
			state.authTokenExpiry = expiry;
		},
	},
	actions: {
		fetchAuthToken: async ({ commit }) => {
			try {
				
				const params = new URLSearchParams();
				params.append("client_id", config.AUTH_CLIENT_ID);
				params.append("client_secret", config.AUTH_CLIENT_SECRET);
				params.append("grant_type", "client_credentials");
				params.append("scope", "read");

				await axios
					.post(config.ADMIN_API_HOST+"/oauth/token", params, {
						withCredentials: false,
						transformRequest: [
							(data, headers) => {
								headers.post["Content-Type"] =
									"application/x-www-form-urlencoded";
								return data;
							},
							...axios.defaults.transformRequest,
						],
					})
					.then((response) => {
						// console.log('response:' + JSON.safeStringify(response))
						const patchedToken = "Bearer " + response.data.access_token;
						commit("SET_AUTH_TOKEN", patchedToken);
						console.log(
							"Client token fetched and saved to store:" + patchedToken
						);

						// Set expiry as cache
						let expirybuffer = 0;
						let expiredAt = new Date(
							new Date().getTime() +
								(response.data.expires_in - expirybuffer) * 1000
						);
						commit("SET_AUTH_TOKEN_EXPIRY", expiredAt);
					});
			} catch (e) {
				// Cookie.remove('token');
				console.log("something happen:" + e);
				commit("FETCH_AUTH_TOKEN_FAILURE");
			}
		},
	},
};
